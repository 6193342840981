define("discourse/plugins/discourse-user-card-badges/discourse/initializers/update-post", ["exports", "virtual-dom", "discourse/lib/icon-library", "discourse/lib/plugin-api"], function (_exports, _virtualDom, _iconLibrary, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "update-post",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.user_card_badges_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.12", api => {
        api.decorateWidget("post-avatar:after", helper => {
          const badgesList = [];
          helper.widget.findAncestorModel().user_badges.forEach(badge => {
            const badgeClass = "badge-type-" + badge.badge_type.toLowerCase();
            const badgeElement = badge.image ? (0, _virtualDom.h)("img", {
              src: badge.image
            }) : (0, _iconLibrary.iconNode)(badge.icon);
            badgesList.push((0, _virtualDom.h)("span.user-badge." + badgeClass, {
              title: badge.name,
              "data-badge-name": badge.name
            }, [badgeElement]));
          });
          return helper.h("div.selected-badges", {}, badgesList);
        });
      });
    }
  };
});